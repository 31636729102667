import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components'
import {setmode, finded, file, setlang } from '../actions/card'

const Addr = styled.input `
  padding: 4px 5px;
  font-size: 16px;
  float: left;
  margin: 4px;
  width: 67%;
  text-align: center;
  border: groove white;
  height: 22px;
`;
const Addr0 = styled.input `
  padding: 4px 5px;
  font-size: 16px;
  float: left;
  margin: 4px;
  width: 81%;
  text-align: center;
  border: groove white;
  height: 22px;
`;
const INP = styled.input.attrs({
  type: 'submit',
  value: ''
})` 
  float: left;
  margin: 5px 3px 5px 3px;;
  height: 36px;
  width: 36px;
  border: 1px solid white;
  padding: 2.2px;
  background-image: url(/Users/0/rt.png);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const RUS = styled.input ` 
  float: left;
  margin: 5px 3px 5px 3px;
  height: 30px;
  width: 30px;
  border: 1px solid white;
  padding: 2.2px;
  cursor: pointer;
`;
const ENG = styled.input ` 
  float: left;
  margin: 5px 3px 5px 3px;
  height: 30px;
  width: 30px;
  border: 1px solid white;
  padding: 2.2px;
  cursor: pointer;
`;
const DEL = styled.input `
  float: left;
  margin: 5px 3px 5px 3px;
  height: 30px;
  width: 30px;
  border: 1px solid white;
  padding: 2.2px;
  cursor: pointer;
`;
export const Scan = () => {
  const dispatch = useDispatch()  
  const info     = useSelector(state => state.post.info)
  const lang     = useSelector(state => state.post.lang)
  const mode     = useSelector(state => state.post.mode)
  const logon    = useSelector(state => state.post.logon)
  const dnside   = useSelector(state => state.post.dnside)
  const uname    = useSelector(state => state.post.name)
  const showPik  = useSelector(state => state.post.showPik)
  const picters  = useSelector(state => state.post.picts)
  const usernum  = useSelector(state => state.post.usernum)
  const fileData = useSelector(state => state.post.fileData)
  const pers     = useSelector(state => state.post.pers)
  const person  = useSelector(state => state.post.person)
  
  const [name, setName] = useState("")
  
  let text, form
  
  const type = (lett) => {
    setName(lett)
    dispatch(setmode(1))
  }
  const find = (e) => {
    e.preventDefault()
    if (logon === 1 && info) {
      dispatch(finded(info[1][lang], lang))
    }    
  }
  const send = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append("file", fileData);
    dispatch(file(data, usernum, showPik, name))
  }
  if (logon === 0) {
    if (showPik === 0) {
      form = <><Addr0 type = "text"
                    value = {uname}>
               </Addr0>
               <INP></INP>
             </>      
    }
    if (showPik !== 0) {
      text = picters[showPik - 1][4]
      if (dnside === 0) {
        form = <form onSubmit = { send }>
                <Addr0 type = "text"
                     value = {pers[3]}
                  onChange = {e => type(e.target.value)}
                >
                </Addr0>
                <INP></INP>
               </form>
      } 
    }
    form = <>  
        <DEL
         type = "image"
          src = { '/Users/0/kros.png' }
          alt = ''
      onClick = { () => type("") } />
    {form}
    </>
  }
  if (logon === 1) {
    if (mode === 0 && info) {
      text = info[1][lang]
    }
    if (mode === 1) {
      text = name
    }
    form = <form onSubmit = { find }>
             <Addr type = "text"
                  value = { text }
               onChange = { e => type(e.target.value) }
             >
             </Addr>
             <INP></INP>
           </form>
    form = <><RUS
        type  = "image"
         src  = { '/Users/0/rus.png' }
         alt  = ''
      onClick = { () => dispatch(setlang(0)) } />    
    <DEL
         type = "image"
          src = { '/Users/0/kros.png' }
          alt = ''
      onClick = { () => type("") } />
    {form}
    <ENG
        type  = "image"
         src  = {'/Users/0/eng.png'}
         alt  = ''
      onClick = {() => dispatch(setlang(1))} /></>
  }
  if (logon === 3) {
    if (showPik === 0) {
      form = <><Addr type = "text"
                    value = {person[1]}>
               </Addr>
               <INP></INP>
             </>      
    }
    form = <>  
        <DEL
         type = "image"
          src = { '/Users/0/kros.png' }
          alt = ''
      onClick = "" />
    {form}
    </>
  }
  return (
    <>
    {form}
    </>
  )
}
export default (Scan)