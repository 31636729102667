import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import styled from 'styled-components'

const Line = styled.div`
  background: url(/Users/0/clock.png) no-repeat;
  color: azure;
  height: 68px;
  margin-left: 222px;
  margin-right: 212px;
`;
const H4 = styled.h4`
  font-family: 'BebasNeueRegular', Arial, Helvetica, sans-serif;
  padding-left: 27px;
  color: azure;
  margin: 1px;
  float: left;
  width: 100%;
  font-weight: 100;
`;
const BUTT = styled.button`
  margin-left: 54px;
  cursor: pointer;
  font-size: 16px;
`;

export const Time = () => {
  const info = useSelector(state => state.post.info)
  const [date0, setdate0] = useState(new Date())
  const [date1, setdate1] = useState(new Date())

  useEffect(()=>{
    const tick = () => {
      const d = new Date()
      const hours = (info[4] - info[4]%60)/60
      const min = info[4]%60
      d.setHours(d.getHours() + hours)
      d.setMinutes(d.getMinutes() + min)
      setdate0 (new Date())
      setdate1 (d)
    }
    const timerID = setInterval( () => tick(), 1000 )
    return () => {
      clearInterval(timerID)
    }
  },[info])
  
  return (
    <Line>
      <H4>
        {date0.toLocaleTimeString()}
      </H4>
      <BUTT>
        {(( info[4]-info[4]%60 )/60 < 10  ? "0" : "" ) + ( info[4]-info[4]%60 )/60 + ':'+ 
          ( info[4]%60 < 10 ? "0" : "" ) + info[4]%60 }
      </BUTT>
      <H4>
        {date1.toLocaleTimeString()}
      </H4>
    </Line>
  )
}
export default (Time)