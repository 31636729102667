import React, {useState, useEffect} from "react"
import StarRatingComponent from 'react-star-rating-component'
import {useDispatch, useSelector} from "react-redux";
import {text} from '../actions/card'

export const Coll = () => {
  const dispatch = useDispatch()
  const thepers = useSelector(state => state.post.thepers)
  const stam    = useSelector(state => state.post.stam)
  const film    = useSelector(state => state.post.film)
  const numb    = useSelector(state => state.post.numb)

  const scrn = {
    background: 'url(/Users/0/tv.png) no-repeat',
    backgroundSize: '100% 99%',
    height: '291px'
  }
  const post = {
    float: 'left',
    width: '167px',
    marginLeft: '20px',
    marginTop: '15px'
  }
  const foto = {
    float: 'left',
    width: '360px',
    margin: '15px 10px 10px 22px',
    overflow: 'hidden',
    height: '220px'
  }
  const imgn = {
    float: 'left',
    width: '170px',
    height: '102px',
    marginLeft: '5px',
    overflow: 'hidden',
    border: 'black solid  1px'
  }
  const polr = {
    position: 'relative',
    width: '100%',
    color: 'black',
    fontSize: '12px',
    marginTop: '4px',
    background: 'url(/Users/0/tv.png) no-repeat',
    height: '360px',
    backgroundSize: '100%95%'
  }
  const innr = {
    position: 'relative',
    width: '90%',
    margin: 'auto',
    top: '40px'
  }
  const panl = {
    width: '100%',
    height: '75px'
  }
  const midl = {
    width: '100%',
    height: '135px'
  }

  const rew = film[numb][5]
  const [rewier, setRewier] = useState(1)
  const [Text, setText] = useState(rew)

  let placard
  useEffect(() => {
    setText(() => rew);
  }, [rew]); 
  
  const sendText = (Text, nextValue) => {
    dispatch(text(stam, film[numb][1], Text, nextValue))
  }  
  if (rewier === 0) {
    placard = film[numb][5]
  }
  if (rewier === 1) {
    if (thepers === stam) {
      const rating = film[numb][6]
      placard = <div><textarea name  = "" cols="65" rows="16"  
                               value = {Text}
                            onChange = {e => setText(e.target.value)}
                      ></textarea>
                      <StarRatingComponent 
                                   name = "rate"
                              className = { "Rating" }
                              starCount = { 5 }
                              starColor = { '#3b00ff' }
                         emptyStarColor = { '#819190' }
                         onStarClick    = {(nextValue) => sendText(Text, nextValue)}
                         editing        = {true}
                         value          = {rating}
                       />
                </div>
    }
    else {
      placard = <div>{ film[numb][1] }</div>
    }
  }
  if (rewier === 2) {
    placard = ''
  } 

  const pict = film[numb][2] + film[numb][1] + 'p2.jpg'
  const nums = [1, 2, 3, 4]
  const picts = <div>
                  {nums.map(num => (
                    <div style = {imgn}>  
                      <input type  = "image"
                           onClick = ''
                           src     = {film[numb][2] + film[numb][1] + 'm' + num + '.jpg'}
                           alt     = ''
                      />
                    </div>
                  ))}
                </div> 

  const list = ['Отзыв', 'Оценки'] 
  const buts = <div style = {panl}>
                 <button  onClick = {() => setRewier(1)}>{list[0]}</button> 
                 <button  onClick = {() => setRewier(2)}>{list[1]}</button>
               </div>
  const love = <div><input type = "image" 
                            src = '/Users/0/h.png'
                        onClick = ''
                            alt = ''
                    ></input></div>
  return (
      <><div style = {scrn}>
          <img style = {post}
                 src = {pict}
                 alt = ''
          />
          <div style = {foto}>
            {picts}
          </div>
            {love}
          </div>
          <div style = {midl}>
            {buts}
        </div>  
        <div style = {polr}><div style = {innr}>{placard}</div></div>
      </>
  )
}
export default (Coll)