import React from "react"
import { useSelector } from "react-redux";

import styled from 'styled-components'
const TD = styled.td `
  background-color: #fff;
  font-size: 9px;
`;
const TAB = styled.table `
  margin: 0px 61px;
  height: 75px;
  width: 80%;
`;
const IMG = styled.img`
  float: left;
`;
const INFO = styled.div`
  float: right;
`;
export const Diag = () => {
  const info = useSelector(state => state.post.info)
  const lang = useSelector(state => state.post.lang)
  let x, y, z, name
  const b = Number(info[10][0].slice(1).replace(/[^a-zа-яё0-9\s]/gi, ''))
  const u = Number(info[10][2].slice(1).replace(/[^a-zа-яё0-9\s]/gi, ''))
  const w = Number(info[10][3].slice(1).replace(/[^a-zа-яё0-9\s]/gi, ''))
  if (lang === 1) {
    name = ['Budget', 'Gross US & Can', 'Gross world']
  }
  if (lang === 0) {
    name = ['Бюджет', 'Сборы в США и Кан.', 'Сборы в мире']
  }
  if (b > u && b > w) {
    x = 300
    y = 300 / (b / u)
    z = 300 / (b / w)
  }
  if (u > b && u > w) {
    y = 300
    x = 300 / (u / b)
    z = 300 / (u / w)
  }
  if (w > b && w > u) {
    z = 300
    x = 300 / (w / b)
    y = 300 / (w / u)
  }
  return (
      <div>
        <TAB cellspacing="0" cellpadding="0">
        <tr>
        <TD>{name[0]}</TD>
        <TD class="value"><IMG src="/Users/0/bar.png" alt="" width= {x} height="16" /><INFO>{info[10][0]}</INFO></TD>
        </tr>
        <tr>
        <TD>{name[1]}</TD>
        <TD class="value"><IMG src="/Users/0/bar.png" alt="" width= {y} height="16" /><INFO>{info[10][2]}</INFO></TD>
        </tr>
        <tr>
        <TD>{name[2]}</TD>
        <TD class="value"><IMG src="/Users/0/bar.png" alt="" width= {z} height="16" /><INFO>{info[10][3]}</INFO></TD>
        </tr>
        </TAB>
      </div>
  )
}
export default (Diag)