import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {entr} from '../actions/card'


export const Pass = () => {
  const dispatch = useDispatch()
  const Pass  = useSelector(state => state.post.Pass)
  const [pass1, setPass1] = useState("")
  const [pass2, setPass2] = useState("")
  const [pass3, setPass3] = useState("")
  const [pass4, setPass4] = useState("")
  const [pass5, setPass5] = useState("")

  const logn = {
    top: '5px',
    position: 'relative',
    fontSize: '20px'
  }
  const radn = {
    margin: '2px 11px'
  }
  let pass
  const vals = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I','J', 'K', 'L', 'M', 'N', 'O', 'P']

  if (pass1 && pass2 && pass3 && pass4 && pass5) {
    pass = <button onClick = {() => dispatch(entr(pass1 + pass2 + pass3 + pass4 + pass5))}>
            {pass1 + pass2 + pass3 + pass4 + pass5}
           </button>
  }
  else {
    pass = pass1 + pass2 + pass3 + pass4 + pass5
  }

  return (
      <div style = {logn}>
          {Pass}
        <p>
        {vals.map(val => (
          <label key="pass">
            <input style = {radn}
              type="radio"
              name="1"
              value={val}
              onClick={e => setPass1(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        <hr></hr>
        <p>
        {vals.map(val => (
          <label key="pass">
            <input style = {radn}
              type="radio" 
              name="2"
              value={val}
              onClick={e => setPass2(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        <hr></hr>
        <p>
        {vals.map(val => (
          <label key="pass">
            <input style = {radn}
              type="radio"
              name="3"
              value={val}
              onClick={e => setPass3(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        <hr></hr>
        <p>
        {vals.map(val => (
          <label key="pass">
            <input style = {radn}
              type="radio" 
              name="4"
              value={val}
              onClick={e => setPass4(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        <hr></hr>
        <p>
        {vals.map(val => (
          <label key="pass">
            <input style = {radn}
              type="radio"
              name="5"
              value={val}
              onClick={e => setPass5(e.target.value)}
              />{val}
          </label>   
        ))}
        </p>
        {pass}
      </div>
  )
}

export default (Pass)