import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components'
import Year from './Year'
import Reit from './Reit'
import Time from './Time'
import Diag from './Diag'
import Cast from './Cast'
import Sing from './Sing'
import Awad from './Awad'
import Scan from './Scan'
import {Love, updateplacard, setyear0, getfilmdata, samp} from '../actions/card'

const SCRN = styled.div`
  background: url(/Users/0/tv.png) no-repeat;
  background-size: 100% 99%;
  height: 288px;
`;
const POST = styled.img`
  float: left;
  margin-left: 17px;
  margin-top: 15px;
`;
const FOTO = styled.div`
  float: left;
  width: 360px;
  margin: 15px 10px 10px 22px;
  overflow: hidden;
  height: 240px;
`;
const MIDL = styled.div`
  width: 100%;
  height: 123px;
`;
const PANL = styled.div`
  width: 100%;
  height: 75px;
`;
const BUTS = styled.div`
  height: 67px;
`;
const POLR = styled.div`
  position: relative;
  width: 100%;
  color: black;
  font-size: 12px;
  margin-top: 4px;
  background: url(/Users/0/board.png) no-repeat;
  height: 292px;
  background-size: 100% 100%;
`;
const INNER = styled.div`
  position: relative;
  width: 89%;
  margin: 0 39px;
  top: 40px;
  height: 223px;
  overflow-y: scroll;
  font-family: fantasy;
  font-size: 15px;
`;
const IMG = styled.input`
  width: 170px;
  margin-left: 10px;
`;
const KAD = styled.input`
  width: 332px;
  margin-left: 10px;
`;
const BILL = styled.img`
  width: 57px;
  margin-left: 4px;
  margin-top: 7px;
  margin-bottom: 4px;
  cursor: pointer;
`;
const OLD = styled.div`
  float: left;
  margin: 4px;
`;
const MAIN = styled.div`
  height: 360px;
`;

export const Post = () => {
  const dispatch = useDispatch()
  const info     = useSelector(state => state.post.info)
  const butsc    = useSelector(state => state.post.butsc)
  const butsg    = useSelector(state => state.post.butsg)
  const butsp    = useSelector(state => state.post.butsp)
  const placard  = useSelector(state => state.post.placard)
  const love     = useSelector(state => state.post.love)
  const usernum  = useSelector(state => state.post.usernum)
  const year0    = useSelector(state => state.post.year0)
  const lang     = useSelector(state => state.post.lang)
  const side     = useSelector(state => state.post.side)
  const coun     = useSelector(state => state.post.coun)
  const awar     = useSelector(state => state.post.awar)
  const stud     = useSelector(state => state.post.stud)
  const song     = useSelector(state => state.post.song)
  const stam     = useSelector(state => state.post.user)
  const film     = useSelector(state => state.post.film)

  const [ButNumbl, setButNumbl] = useState(0)
  const [showPiks, setshowPiks] = useState(0)
  const [item, setitem]         = useState(1)

  useEffect(()=>{
    if (!info) {
      dispatch(getfilmdata(1, '', 2, '0f100'))
    }
  },[dispatch, info])

  let post, midl, butsl, butsm, pics, year, list
  const items = [1, 2, 3, 4]

  const mylove = (code, user, side) => {
    if (stam) {
      dispatch(Love(code, user, side))
    }
    else {
      let base = code.match(/\d+/)
      film.unshift([ film.length-4, code, 'https://kshisa.ru/images/' + base[0] + '/', 'p1.jpg', code, '', '', 5 ])
      dispatch(samp(2, '0f100p1.jpg', film.length,  film))
      dispatch(getfilmdata(1, '', 2))
    }
  }
  const showPik = (pic, item) => { setshowPiks(pic) 
                                   setitem(item)
                                 }
  if (showPiks === 0) {
    if (info) {
      pics = items.map((item, index) => 
                  ( <IMG type = "image"
                      onClick = {() => showPik(1, item)}
                          src = {'https://kshisa.ru/images/' + info[3] + '/' + info[0] +  'm' + item + '.jpg'}
                          alt = { index } />
                                        ))
      if (info[9]) { pics = <div>{ pics }<OLD>{info[9][lang]}</OLD></div> }
      pics = <div>{ pics } {<input type = "image"
                                onClick = {() => mylove(info[0], usernum, side)} 
                                    src = {love}
                                    alt = { side }
                                  style = {{ marginRight: '10px' }} />
                            }
             </div>
    }
  }
  if (showPiks !== 0) {
    pics =  <KAD type = "image"
              onClick = {() => setshowPiks(0)} 
                  src = {'https://kshisa.ru/images/' + info[3] + '/' + info[0] +  'k' + item + '.jpg'}
            />
  }

  if (year0 === 0 && info) { 
    year = <button onClick={() => dispatch(setyear0(1))}>{ info[3] }</button>
  } 
  else { year = <Year/> }

  if (ButNumbl === 0) { butsl = butsg.map(but => (<BILL
                                                    src = {'Users/0/'+ but +'.png'}
                                                    alt =''
                                                  />))}
  if (ButNumbl === 1) { butsl = butsc.map(but => (<BILL
                                                    src = {'Users/0/'+ but +'.png'}
                                                    alt =''
                                                  />))}
  if (ButNumbl === 9) { butsl = butsp.map(but => (<BILL
                                                    src = {'Users/0/'+ but[1] +'.png'}
                                                    alt = ''
                                                onClick = { () => dispatch(updateplacard(6, but[0])) }
                                                  />))}
  if (ButNumbl === 10) { butsl = <><Awad/></>}
  if (ButNumbl === 11) { butsl = stud.map(but => (<BILL
                                                    src = {'https://kshisa.ru/images/2/'+ but[2] +'.png'}
                                                    alt = ''
                                                onClick = { () => dispatch(updateplacard(8, but[0])) }
                                                  />))}
  if (ButNumbl === 3) { butsl = <><Reit/></> }
  if (ButNumbl === 4) { butsl = <><Time/></> }    
  if (ButNumbl === 5) { butsl = <><Diag/></> }

  if (placard === 0) { butsm = <Cast folk={1}/> }
  if (placard === 5) { butsm = <Cast folk={2}/> } 
  if (placard === 1) {
    if (lang === 0) { butsm = info[5] }
    if (lang === 1) { butsm = info[6] }
  }
  if (placard === 2) { butsm = <Sing/> }
  if (placard === 4) {
    if (lang === 0) { butsm = info[8] }
    if (lang === 1) { butsm = info[7] }
  }
  if (placard === 6) {
    butsm = butsp[coun][2].map(loc => (<div><button>{loc}</button></div>))
  }
  if (placard === 7) { butsm = awar[coun][2] }
  if (placard === 8) { butsm = awar[coun][1] }

  if (lang === 1) {
    list = ['Genre', 'Country', 'Cast', 'Review', 'Rating', 'Time', 'Finansy', 'Tags',
            'Songs', 'Places', 'Awards', 'Crew', 'Studio']
  }
  if (lang === 0) {
    list = ['Жанр', 'Страна', 'Актёры', 'Обзор', 'Рейтинг', 'Время', 'Финансы', 'Слоган', 
            'Музыка', 'Локации', 'Награды', 'Создатели', 'Студии']
  }
  if (placard === 6) {
    post =  <MAIN>
              <img src = {'Users/0/' + butsp[coun][1] + 'p1.png' } alt = {butsp[coun][1]}></img>
            </MAIN>
  }
  else {
    post =  <MAIN><SCRN><POST
                          src = {'http://kshisa.ru/images/' + info[3] + '/' + info[0] + 'p2.jpg'}
                          alt = ''
                        />
                        <FOTO>{ pics }</FOTO>  
                  </SCRN>
                  <Scan/>
                  {year}
            </MAIN>
  }
  midl =  <><button onClick = {() => setButNumbl(0)}>{list[0]}</button>
            <button onClick = {() => setButNumbl(1)}>{list[1]}</button>
            <button onClick = {() => setButNumbl(3)}>{list[4]}</button>
            <button onClick = {() => setButNumbl(4)}>{list[5]}</button>
            <button onClick = {() => setButNumbl(5)}>{list[6]}</button></>
  if (butsp.length > 0){ midl = <>{midl}<button onClick = {() => setButNumbl(9)}>{list[9]}</button></> }
  if (awar.length > 0) { midl = <>{midl}<button onClick = {() => setButNumbl(10)}>{list[10]}</button></> } 
  if (stud.length > 0) { midl = <>{midl}<button onClick = {() => setButNumbl(11)}>{list[12]}</button></> } 
    
  midl =  <>{midl}<PANL><BUTS>{ butsl }</BUTS></PANL>
            <button onClick = { () => dispatch(updateplacard(0, '')) }>{list[2]}</button>
            <button onClick = { () => dispatch(updateplacard(5, '')) }>{list[11]}</button>
            <button onClick = { () => dispatch(updateplacard(1, '')) }>{list[3]}</button>
            <button onClick = { () => dispatch(updateplacard(4, '')) }>{list[7]}</button></>
  if (song.length > 0) { midl = <>{midl}<button onClick = { () => dispatch(updateplacard(2, '')) }>{list[8]}</button></> } 

  return (
    <>
      {post}
      <MIDL>{midl}</MIDL>
      <POLR><INNER>{butsm}</INNER></POLR>
    </>
  )
}
export default (Post)