import React from "react";
import Hall from './Hall'
import Pass from './Pass'
import Avat from './Avat'
import Coll from './Coll'
import User from './User'
import Chat from './Chat'
import Post from './Post'
import Paus from './Paus'
import { useSelector } from "react-redux";

export const Form = ({ side, socket }) => {

  const login = useSelector(state => state.post.login)
  const logon = useSelector(state => state.post.logon)
  let card

  const cent = {
    textAlign: "center",
    fontWeight: "600",
    width: "63%",
    height: "500px",
    float: "left",
    padding: "0 4px",
  }
  const hall = {
    textAlign: "center",
    fontWeight: "600",
    width: "99%",
    float: "left",
    padding: "12px 4px 0",
    background: 'url(/Users/0/tv.png) no-repeat',
    backgroundSize: '100% 100%',
    height: '98%',
  }
  const scen = {
    textAlign: "center",
    fontWeight: "600",
    width: "99%",
    float: "left",
    padding: "12px 4px 0",
    background: 'url(/Users/0/scene.jpg) no-repeat',
    height: '98%',
  }
  if (side === 1) {
    if (login === 0) {
      card = <div style = {hall}><Hall socket={socket}/></div>
    }  
    if (login === 1) {
      card = <div style = {scen}><Pass/></div>
    }
    if (login === 2) {
      card = <div style = {scen}><Avat/></div>
    }
    if (login === 3) {
      card = <div style = {scen}><Paus/></div>
    }   
  }
  if (side === 2) { 
    if (logon === 0) {
      card = <div style = {cent}><User socket={socket}/></div>
    } 
    if (logon === 1) {
      card = <div style = {cent}><Post/></div>
    }
    if (logon === 2) {
      card = <div style = {cent}><Coll/></div>
    }    
    if (logon === 3) {
      card = <div style = {cent}><User socket={socket}/></div>
    }
    if (logon === 4) {
      card = <div style = {cent}><Chat socket={socket}/></div>
    }
  }
  return (
    <>
    {card}
    </>
  )
}
export default (Form)