import React from "react"
import { useSelector } from "react-redux";
import styled from 'styled-components'

const Line1 = styled.div `
  color: azure;
  float: left;
  width: 4%;
  margin-left: 35%;
`;
const Line2 = styled.div `
  color: azure;
  float: left;
  width: 4%;
  margin-left: 15%;
`;
const Imgs = styled.img `
  width: 68px;
  margin-top: 4px;
  height: 33px;
`;
const H3 = styled.h3`
  color: black;
  margin: 0px 0px;
  width: 252%;
  font-size: 19px;
  border: groove black;
  border-radius: 5px;
  background-color: white;
`;

export const Reit = () => {
  const info = useSelector(state => state.post.info)
  let text
  if (info[2][1]) { text = info[2][1] }
  else { text = '0' }
  
  return (
      <>
      <Line1>
        <Imgs
          src = { '/Users/0/imdb.png' }
          alt = '' 
        >
        </Imgs >
        <H3>{info[2][0] / 10}</H3>  
      </Line1>
      <Line2>
        <Imgs
          src = { '/Users/0/meta.png' }
          alt = '' 
        >
        </Imgs > 
        <H3>{text}</H3>
      </Line2>
      </>
    )
}
export default (Reit)