import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux"
import {start, list} from '../actions/card'

export const Head = ({socket }) => {
  const dispatch = useDispatch()
  const login = useSelector(state => state.post.login)
  const text  = useSelector(state => state.post.text)

  const user  = useSelector(state => state.post.user)
  const [ids, setUsers] = useState([]);
  useEffect(() => {
    socket.on('newUserResponse', (data) => setUsers(data));
    console.log(ids)
    const chat = []
    for (let x = 0; x < ids.length; x++) {
      if (ids[x][1] === user){
        continue
      }
      else {
        chat.push([x, ids[x][1], '/Users/0/', 'p1.jpg', ids[x][0]])
      }
    }
    if (ids.length < 5) {
      for (let z = ids.length; z < 5; z++) {
        chat.push(['', 'blank', '/Users/0/', 'p1.png'])
      }
    }
    console.log(chat)
    dispatch(list(chat))  
  }, [socket, ids, dispatch, user])
  
  let ring
  const head = {
    height: "69px",
    border: "groove white",
    backgroundColor: "white",
    margin: '9px 0px'
  }
  const icon = {
    position: 'relative',
    width: '67px',
    height: '67px',
    margin: 'auto',
    zIndex: '3',
    boxShadow: '0 0 0 10px rgb(255 255 255 / 20%), 0 0 25px 2px rgb(0 0 0 / 40%), inset 0 0 0 15px rgb(255 255 155 / 40%)',
    borderRadius: '999px',
    right: '-18%',
    float: 'left'
  }  
  const buts = {
    width: "42px",
    height: "42px",
    float: "left",
    padding: "12.3px",
    zIndex: "3"
  }
  const news = {
    fontSize: "27px",
    float: "left",
    width: "74%",
    paddingTop: "9px",
  }  
  const logo = {
    width: "177px",
    position: 'relative',
  }

  if (login === 0) {
    ring =  <input type    = "image" 
                   name    = "avat"
                   src     = {'/Users/0/login.png' }
                   onClick = {() => dispatch(start(1, 'Type password'))}
                   style   = {buts}
                   alt     = 'butt'
            /> 
  }
  if (login === 1) {
    ring =  <input type    = "image"
                   name    = "avat"
                   src     = {'/Users/0/join.png' }
                   onClick = {() => dispatch(start(2, 'Fill the form'))}
                   style   = {buts}
                   alt     = 'butt'
            /> 
  }
  if (login === 2) {
    ring =  <input type    = "image"
                   name    = "avat"
                   src     = {'/Users/0/intr.png' }
                   onClick = {() => dispatch(start(0, 'Watch the film'))}
                   style   = {buts}
                   alt     = 'butt'
            /> 
  }
  return (
    <header style = {head}>
      <div style = {news}>
        { text }
      </div>
      <img style = {logo}
        src = {'/Users/0/gorisond.png'}
        alt = 'logo'
      />
      <div style = {icon}>
        {ring}
      </div>     
    </header>
  )
}

export default (Head)