import {
  LOGON,
  GET_FILMDATA,
  PEOPLE,
  FILMS,
  PROP,
  CHAT,
  LIST,
  MENU,
  GET_TAPE,
  LOVE,
  UPD_PLAC,
  YEAR0,
  FILEDATA,
  PIC,
  GET_PICTS,
  FRIEND,
  TEXT,
  GET_USER,
  GET_PERSON,
  GET_FIND,
  MODE,
  LANG,
  LOGIN,
  UPD_COUNT,
  UPD_COUNTR,
  GET_NEXT,
  GET_NEXTR,
  COLL,
  HALL,
  NEWU,
  SELF,
  NUMB,
  ADVT,
  SAMP
} from '../constants'

const initialState = {
  text:     '',
  login:    1,
  logon:    1,
  user:     '',
  stam:     '',
  numb:     0,
  users:    [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  dnside:   4,             
  swch:     '0f100p1.jpg',
  frnd:     [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  temp:     [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  film:     [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  fold:     [[ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ],
             [ '', 'blank', '/Users/0/', 'p1.png' ]],
  info:     '',
  butsc:    [],
  butsg:    [],
  butsp:    [], 
  placard:  0, 
  love:     '',
  usernum:  '',
  year0:    0,
  lang:     1,
  side:     0,
  coun:     0,
  awar:     [],
  stud:     [],
  song:     [],
  bill:     [],
  man:      0,
  pers:     '',
  code:     '',
  birth:    '',
  picts:    [],
  showPik:  0,
  person:   [],
  thepers:  '',
  years:    [ 1895, 1896, 1897, 1898, 1899, 1900, 1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908, 
              1909, 1910, 1911, 1912, 1913, 1914, 1915, 1916, 1917, 1918, 1919, 1920, 1921, 1922, 
              1923, 1924, 1925, 1926, 1927, 1928, 1929, 1930, 1931, 1932, 1933, 1934, 1935, 1936, 
              1937, 1938, 1939, 1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949, 1950, 
              1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 
              1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 
              1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 
              1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006,
              2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 
              2021, 2022, 2023, 2024],
  cast:     [],
  crew:     [],
  name1:    '',
  name2:    '',
  year1:    '',
  year2:    '',
  find:     [],
  mode:     0,
  name:     '',
  fileData: '',
  Pass:     '',
  avat:     [[ 1,  '1f200224092722', '' ],
             [ 2,  '1f200225202045', '' ],
             [ 3,  '1f200225224432', '' ],
             [ 4,  '1f200227210557', '' ],
             [ 5,  '1f200227211103', '' ],
             [ 6,  '1f200301092039', '' ],
             [ 7,  '1f200301114207', '' ],
             [ 8,  '1f200301181324', '' ],
             [ 9,  '1f200301221612', '' ],
             [ 10, '1f200301231130', '' ],
             [ 11, '1f200301232453', '' ],
             [ 12, '1f200305124912', '' ],
             [ 13, '1f200309164023', '' ],
             [ 14, '1f200420114028', '' ],
             [ 15, '1f200430105625', '' ],
             [ 16, '1f200430124359', '' ]],
  trigger:  0,
  triggerr: 0,
  count:    1,
  countr:   1,
  numbb:    8, 
  numbs:    5,
  numba:    0,
  tape:     '0f100',
  hall:     '1942f220624160517',
  entr:     0,
  chat:     [],
  menu:     [[ 1, 'film', '/Users/0/', '.jpg', '', '', '', 1 ],
             [ 2, 'coll', '/Users/0/', '.png', '', '', '', 2 ],    
             [ 3, 'frnd', '/Users/0/', '.png', '', '', '', 3 ], 
             [ 4, 'cine', '/Users/0/', '.png', '', '', '', 4 ]]
}
export default function Post (state = initialState, action) {
  switch (action.type) {
    case LOGON:
      return {
        ...state,
        name1: '',
        name2: '',
        year1: '',
        year2: '',
        login: action.login,
        text:  action.text
      }
    case GET_FILMDATA:
      return {
        ...state,
        info:  action.card[0],
        base:  action.card[7],
        butsc: action.card[1],
        butsg: action.card[2],
        butsp: action.card[10],
        cast:  action.card[3],       
        crew:  action.card[12],
        love:  action.card[4],
        text:  action.card[5],
        side:  action.card[6],
        song:  action.card[9],
        awar:  action.card[11],
        stud:  action.card[13],
        login: 1,
        placard: 0,
        mode: 0
      }
      case GET_USER:
        return {
          ...state,
          login:   0,
          logon:   0,
          swch:    'swich.png',
          usernum: action.user[0],
          user:    action.user[1],
          name:    action.user[2],
          stam:    action.user[0],
          birth:   action.user[3],
          bill:    action.bill,
          temp:    action.frnd,
          frnd:    action.frnd,
          picts:   action.pict,
          Pass:   '',
          placard: 0,
          text:    action.text,
          film:    action.film,
          thepers: action.user[0],
          users:   action.soci,
          entr: 1
        }
    case ADVT:
      return {
        ...state,
        text: action.text
      }
    case GET_TAPE:
      return {
        ...state,
        swch: action.rank,
        temp: action.post,
        tape: action.tape
      }
    case SELF:
      return {
        ...state,
        logon: 0,
        swch: 'swich.png',
        temp: action.frnd
      }
    case PEOPLE:
      return {
        ...state,
        man:     action.payload,
        dnside:  action.side,
        person:  action.person,
        bill:    action.bill,
        picts:   action.pict,
        thepers: action.pers,
        text:    action.text
      }    
    case FILMS:
      return {
        ...state,
        swch: 'film.jpg',
        pers:  action.film,
        temp:  action.post,
        logon:  1,
        flag:   0
      }
    case PROP:
      return {
        ...state,
        swch:  'frnd.png',
        temp:   action.users,
        picts:  action.picts,
        bill:   action.bill,
        person: action.person,
        logon:  3
      }
    case CHAT:
      return {
        ...state,
        swch: 'cine.png',
        film: action.film,
        temp: action.users,
        logon: 4,
        login: 3
      }
    case LIST:
      return {
        ...state,
        chat: action.chat
      }
    case MENU:
      return {
        ...state,
        swch:  'swich.png',
        temp: action.menu
      }
    case LOVE:
      return {
        ...state,
        side:    action.side,
        usernum: action.usern,
        temp:    action.prop,
        swch:    action.swch,
        chek:    action.numb,
        film:    action.love,
        love:    action.blue
      }
    case SAMP:
      console.log(action.film)
      return {
        ...state,
        side:    action.side,
        swch:    action.swch,
        chek:    action.numb,
        love:    action.blue,
        film:    action.film
      }
    case UPD_PLAC:
      return {
        ...state,
        placard: action.placard,
        coun: action.coun
    }
    case YEAR0:
      return {
        ...state,
        year0: action.year0
    }
    case FILEDATA:
      return{
        ...state,
        fileData: action.fileData,
        temp: action.name
      }
    case PIC:
      return{
        ...state,
        showPik: action.pic,
        dnfind: 1
      } 
    case GET_PICTS:
      return{
        ...state,
        picts: action.picts
      }
    case FRIEND:
      return {
        ...state,
        frnd: action.friends[0],
        temp: action.friends[1],
        man:  action.friends[2],
        dnside: action.friends[3]
      }
    case TEXT:
      return {
        ...state,
        temp: action.film,
        film: action.film
      }
    case GET_PERSON:
      return {
        ...state,
        year1: action.year1,
        year2: action.year2,
        name1: action.name1,
        name2: action.name2
      }
    case MODE:
      return{
        ...state,
        mode: action.mode
      }
    case NUMB:
      return{
        ...state,
        numb: action.numb
      } 
    case GET_FIND:
      return {
        ...state,
        temp: action.find,
        year0: 0
      }
    case LANG:
      return{
        ...state,
        lang: action.lang
      }
    case LOGIN:
      return{
        ...state,
        logon: 0,
        login: 2,
        avat: action.payload
    }
    case UPD_COUNT:
      return {
        ...state,
        count: action.payload
    }
    case UPD_COUNTR:
      return {
        ...state,
        countr: action.payload
    }
    case GET_NEXT:
      return {
        ...state,
        post:    action.payload,
        numbs:   action.numbs,
        trigger: action.trigger
    }
    case GET_NEXTR:
      return {
        ...state,
        post:    action.payload,
        numba:   action.numbs,
        triggerr: action.trigger
    }
    case COLL:
      return {
        ...state,
        swch: 'coll.png',
        temp: action.film,
        film: action.film,
        numb: action.numb,
        logon: 2
    }
    case HALL:
      return {
        ...state,
        hall: action.stam,
        login: action.login
    }
    case NEWU:
      return {
        ...state,
        entr: 0
    }
    default:
			return state
  }
}
