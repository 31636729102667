import React from "react";
import {useSelector} from 'react-redux'

export const Paus = () => {
  const film = useSelector(state => state.post.hall)

  const base = film.match(/\d+/)
  const post =  <img src = {'http://kshisa.ru/images/' + base + '/' + film + 'p2.jpg'}
               alt = ''
          />
  return (
    <>
    {post}
    </>
  )
}

export default (Paus)