import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import { useDispatch, useSelector } from "react-redux";
import {hall} from '../actions/card'

const INNER = styled.div`
  position: relative;
  height: 710px;
  margin: auto;
  overflow-y: scroll;
`;
const Addr = styled.input `
  padding: 4px 5px;
  font-size: 20px;
  float: left;
  margin: 4px;
  width: 68%;
  text-align: center;
  border: groove white;
`;
const INP = styled.input.attrs({
  type: 'submit',
  value: ''
})` 
  float: left;
  margin: 5px 3px 5px 3px;;
  height: 36px;
  width: 36px;
  border: 1px solid white;
  padding: 2.2px;
  background-image: url(/Users/0/rt.png);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const DEL = styled.input `
  float: left;
  margin: 5px 3px 5px 3px;
  height: 30px;
  width: 30px;
  border: 1px solid white;
  padding: 2.2px;
  cursor: pointer;
`;
const MIDL = styled.div`
  width: 100%;
  height: 60px;
`;
const CHAT = styled.div`
  font-size: 13px;
`;
const SEND = styled.div`
  background-color: rgb(194, 243, 194);
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
`;
const RECI = styled.div`
  background-color: #f5ccc2;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  margin-left: auto;
  font-size: 15px;
`;

export const Chat = ({socket}) => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const uname = useSelector(state => state.post.name)
  const stam = useSelector(state => state.post.user)
  const film = useSelector(state => state.post.hall)

  useEffect(() => {
    socket.on('messageResponse', (data) => setMessages([ data, ...messages ]))
  }, [socket, messages])

  useEffect(() => {
    socket.on('nextfilm', (data) => dispatch(hall(data[0], data[1])))
  }, [socket, dispatch])
  useEffect(() => {
    socket.on('contrlsResponse', (data) => {
      const video = document.getElementById("play")
      console.log(video)
      if (data[0] === 'p'){
        video.play()
      }
      if (data.text === 's'){
        video.pause()
      }
    })
  }, [socket])
  const vid = () => {
    socket.emit('film', [film, 0])
  }
  const vidp = () => {
    dispatch(hall(film, 0))
    socket.emit('contrls', { text: 'p' })
  }
  const vids = () => {
    socket.emit('contrls', { text: 's' })
  }

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (message.trim()) {
      socket.emit('message', {
        text: message,
        id: `${socket.id}${Math.random()}`,
        name: uname,
        stam: stam,
        socketID: socket.id,
      })
    }
    setMessage('');
  }

  const polr = <INNER> 
       {messages.map((message) =>
        message.name === uname ? (
          <CHAT key={message.id}>
            <SEND>
            <p>  
            <img src = {'/Users/0/' + message.stam + '.png'}
                 alt = ''/>
            (You)-{message.text}</p>
            </SEND>
          </CHAT>
        ) : (
          <CHAT key={message.id}>
            <RECI>
            <p>  
            <img src = {'/Users/0/' + message.stam + '.png'}
                 alt = ''/>
            ({message.name})-{message.text}</p>
            </RECI>
          </CHAT>
        )
      )}
      </INNER>
const main = <MIDL>
    <DEL
      type="image"
      src={'/Users/0/rt.png'}
      alt=''
      onClick= { () => vid() }/>
    <DEL
      type="image"
      src={'/Users/0/rt.png'}
      alt=''
      onClick= { () => vidp() }/>
    <DEL
      type="image"
      src={'/Users/0/kros.png'}
      alt=''
      onClick= { () => vids() }/>

    <form onSubmit={handleSendMessage}>
      <Addr type="text"
        placeholder="Write message"
        className="message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      >
      </Addr>
      <INP></INP>
    </form>        
  </MIDL>

  return (
    <div>
      { main }
      { polr }
    </div>
  )
}
export default (Chat)